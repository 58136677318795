import './App.css';
import Container from './components/Container'
import Header from './components/Header';

function App() {

  return (
    <>
      <Header/>
      <Container/>
    </>
  );
}

export default App;
